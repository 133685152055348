import * as Yup from "yup";
import { errorMessages } from "../../../../../constants";
import { debounce } from "lodash";

export const getDocValidationSchema = (
    getValidatePattern: (expression?: string) => Promise<boolean>
) => {
    const validateDistribution = debounce(getValidatePattern, 500);
    const validateMonthly = debounce(getValidatePattern, 500);

    return Yup.object().shape({
        distributionReportsPattern: Yup.string().test(
            "distributionReportsPattern-validation",
            errorMessages.invalidValue,
            validateDistribution
        ),
        monthlyReportsPattern: Yup.string().test(
            "monthlyReportsPattern-validation",
            errorMessages.invalidValue,
            validateMonthly
        ),
    });
};
